import React, { FC } from 'react';
import Wrapper from '../../components/wrapper/Wrapper';
import styles from './LatestSection.module.scss';
import { ITheme } from '@/src/assets/interfaces/themeInterface';
import LatestCard from '../../components/latestCard/LatestCard';

type TLatest = {
  themesData: ITheme[];
};

const LatestSection: FC<TLatest> = ({ themesData }) => {
  return (
    <section className={styles.latest__section}>
      <Wrapper>
        {themesData.map((theme) => {
          return <LatestCard key={theme._id} theme={theme} />;
        })}
      </Wrapper>
    </section>
  );
};

export default LatestSection;
