'use client';

import React, { useEffect, useState, useRef } from 'react';
import styles from './HomeCTA.module.scss';
import Button from '../../components/button/Button';

import createAnimationArr from '@/src/assets/helpers/createAnimationArr';
import { useInView } from 'react-intersection-observer';

import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay } from 'swiper/modules';

import 'swiper/css';
import 'swiper/css/autoplay';

import NextImage from '../../components/nextImage/NextImage';
import gallery from './gallery';

type TDirection = 'vertical' | 'horizontal';

const HomeCTA = () => {
  const [img1, img2, img3, img4, img5, img6, img7, img8, img9] = gallery;

  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0,
  });

  const [direction, setDirection] = useState<TDirection>('vertical');
  const [gap, setGap] = useState(16);
  const swiperRef = useRef(null);

  useEffect(() => {
    const handleResize = () => {
      setDirection(window.innerWidth > 820 ? 'vertical' : 'horizontal');
      setGap(window.innerWidth > 1480 ? 24 : 16);
      if (swiperRef.current) {
        //@ts-ignore
        swiperRef.current.swiper.update();
      }
    };

    handleResize();

    window.addEventListener('resize', handleResize);
    window.addEventListener('orientationchange', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
      window.removeEventListener('orientationchange', handleResize);
    };
  }, []);

  const heading = `Premium Standard for Affordable Pricing`;
  const excerpt = `Whether launching your new brand or upgrading an existing one, our affordable themes come with a full pack of features to attract more customers.`;

  const desktopSizes = `(max-width: 376px) 300px, (max-width: 414px) 360px, (max-width: 576px) 400px, (max-width: 748px) 480px, (max-width: 820px) 560px, (max-width: 1024px) 47.01vw, (max-width: 1920px) 54.58vw, 1048px`;

  const sizesMobile = `(max-width: 376px) 75px, (max-width: 414px) 90px, (max-width: 576px) 100px, (max-width: 768px) 120px,(max-width: 820px) 140px, (max-width: 1024px) 11.75vw,(max-width: 1920px) 13.65vw, 262px`;

  return (
    <section className={styles.cta}>
      <div className={styles.cta__left} ref={ref}>
        <h1 className={`heading__1 ${styles.heading} ${inView && styles.animate__heading}`}>
          {createAnimationArr(heading, styles.word__wrapper, 0, 0.01)}
        </h1>
        <p className={`excerpt ${styles.excerpt} ${inView && styles.animate__excerpt}`}>
          {createAnimationArr(excerpt, styles.word__wrapper, 0.3, 0.01)}
        </p>
        <Button
          buttonUrl="/themes"
          action="interactive"
          color="primary-light"
          className={styles.button}>
          Discover Themes
        </Button>
      </div>
      <Swiper
        ref={swiperRef}
        modules={[Autoplay]}
        direction={direction}
        className={`${styles.swiper} gallery-autoplay-swiper`}
        slidesPerView={'auto'}
        allowTouchMove={false}
        shortSwipes={false}
        longSwipes={false}
        loop={true}
        autoplay={{
          disableOnInteraction: false,
          pauseOnMouseEnter: false,
          delay: 0,
        }}
        spaceBetween={gap}
        speed={38000}
        breakpoints={{
          821: {
            speed: 15000,
          },
        }}>
        <SwiperSlide className={styles.background}>
          <div className={`${styles.background__ceil} ${styles.background__desktop}`}>
            <NextImage
              src={img1.src}
              alt={`Background gallery 1`}
              fill
              sizes={desktopSizes}
              loading="lazy"
            />
          </div>
          <div className={`${styles.background__ceil} ${styles.background__desktop}`}>
            <NextImage
              src={img2.src}
              alt={`Background gallery 2`}
              fill
              sizes={desktopSizes}
              loading="lazy"
            />
          </div>
          <div className={`${styles.background__ceil} ${styles.background__mobile}`}>
            <NextImage
              src={img3.src}
              alt={`Background gallery 3`}
              fill
              sizes={sizesMobile}
              loading="lazy"
            />
          </div>
        </SwiperSlide>
        <SwiperSlide className={styles.background}>
          <div className={`${styles.background__ceil} ${styles.background__mobile}`}>
            <NextImage
              src={img4.src}
              alt={`Background gallery 4`}
              fill
              sizes={sizesMobile}
              loading="lazy"
            />
          </div>
          <div className={`${styles.background__ceil} ${styles.background__desktop}`}>
            <NextImage
              src={img5.src}
              alt={`Background gallery 5`}
              fill
              sizes={desktopSizes}
              loading="lazy"
            />
          </div>
          <div className={`${styles.background__ceil} ${styles.background__desktop}`}>
            <NextImage
              src={img6.src}
              alt={`Background gallery 6`}
              fill
              sizes={desktopSizes}
              loading="lazy"
            />
          </div>
        </SwiperSlide>
        <SwiperSlide className={styles.background}>
          <div className={`${styles.background__ceil} ${styles.background__desktop}`}>
            <NextImage
              src={img7.src}
              alt={`Background gallery 7`}
              fill
              sizes={desktopSizes}
              loading="lazy"
            />
          </div>
          <div className={`${styles.background__ceil} ${styles.background__desktop}`}>
            <NextImage
              src={img8.src}
              alt={`Background gallery 8`}
              fill
              sizes={desktopSizes}
              loading="lazy"
            />
          </div>
          <div className={`${styles.background__ceil} ${styles.background__mobile}`}>
            <NextImage
              src={img9.src}
              alt={`Background gallery 9`}
              fill
              sizes={sizesMobile}
              loading="lazy"
            />
          </div>
        </SwiperSlide>
      </Swiper>
    </section>
  );
};

export default HomeCTA;
