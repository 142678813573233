'use client';

import React, { FC } from 'react';
import styles from './LatestCard.module.scss';
import { ITheme } from '@/src/assets/interfaces/themeInterface';
import Label from '../../components/label/Label';
import Button from '../../components/button/Button';
import NextImage from '../../components/nextImage/NextImage';
import { useInView } from 'react-intersection-observer';
import classNames from 'classnames';
import imageHelper from '@/src/assets/helpers/imageHelper';

type TCard = {
  theme: ITheme;
};

const LatestCard: FC<TCard> = ({ theme }) => {
  const { ref, inView } = useInView({
    initialInView: false,
    rootMargin: `-180px`,
    triggerOnce: true,
  });

  return (
    <article className={classNames(styles.theme, { [styles[`theme--animate`]]: inView })} ref={ref}>
      <div className={styles.section__left}>
        <Label>Featured Theme</Label>
        <div className={styles.section__info}>
          <p className={`heading__3 ${styles.heading}`}>{theme.title}</p>
          <p className={`excerpt ${styles.excerpt}`}>{theme.excerpt}</p>
          <div className={styles.buttons}>
            <Button
              buttonUrl={`/themes/${theme.slug}`}
              ariaLabel={`Explore ${theme.title}}`}
              color="primary-dark"
              action="interactive">
              {`Explore ${theme.title}`}
            </Button>
            <Button
              ariaLabel="Explore All Themes"
              color="secondary-light"
              className={styles.button}
              buttonUrl="/themes"
              action="standard">
              Explore All Themes
            </Button>
          </div>
        </div>
      </div>

      <div className={styles.theme__right}>
        <div className={styles.theme__mobile}>
          <NextImage
            src={`${imageHelper(theme.themeMobile).url}`}
            alt={`${theme.title} mobile`}
            fill
            sizes="
                    (max-width: 376px) 26vw,
                    (max-width: 480px) 24vw,
                    (max-width: 576px) 23.33vw,
                    (max-width: 640px) 20vw,
                    (max-width: 820px) 16.22vw,
                    (max-width: 1024px) 9.7vw, 
                                        10.625vw"
          />
        </div>
        <div className={styles.theme__desktop}>
          <NextImage
            src={`${imageHelper(theme.themeDesktop).url}`}
            alt={`${theme.title} desktop`}
            fill
            sizes="
                    (max-width: 376px) 70.85vw,
                    (max-width: 480px) 68vw,
                    (max-width: 576px) 66.67vw,
                    (max-width: 640px) 55.94vw,
                    (max-width: 820px) 54.85vw,
                    (max-width: 1024px) 35.87vw, 
                                        41.67vw"
          />
        </div>
      </div>
    </article>
  );
};

export default LatestCard;
