'use client';

import React, { FC, useEffect } from 'react';
import styles from './FAQSection.module.scss';
import Wrapper from '../../components/wrapper/Wrapper';
import FAQList from '../../components/faqList/FAQList';
import { useInView } from 'react-intersection-observer';
import { IFAQ } from '../../../../assets/interfaces/faqInterface/index';
import createAnimationArr from '@/src/assets/helpers/createAnimationArr';

type TFAQ = {
  faqList: IFAQ[];
};

const FAQSection: FC<TFAQ> = ({ faqList }) => {
  const heading = 'Frequently Asked Questions';
  const excerpt =
    'All the information and assistance you need for a seamless experience with your online shop.';

  const { ref, inView } = useInView({
    triggerOnce: true,
    rootMargin: `-100px 0px`,
    threshold: 0,
  });

  return (
    <section className={styles.faq}>
      <Wrapper className={styles.wrapper}>
        <div className={styles.faq__info} ref={ref}>
          <h2 className={`heading__2 ${styles.heading} ${inView && styles.animate__heading}`}>
            {createAnimationArr(heading, styles.word__wrapper, 0, 0.01)}
          </h2>
          <p className={`excerpt ${styles.excerpt} ${inView && styles.animate__excerpt}`}>
            {createAnimationArr(excerpt, styles.word__wrapper, 0.1, 0.01)}
          </p>
        </div>
        <FAQList faqList={faqList} />
      </Wrapper>
    </section>
  );
};

export default FAQSection;
