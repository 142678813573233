'use client';
import React, { FC, useState } from 'react';
import styles from './FAQList.module.scss';
import FAQItem from '../faqItem/FAQItem';
import { IFAQ } from '../../../../assets/interfaces/faqInterface/index';

type TFAQList = {
  faqList: IFAQ[];
};

const FAQList: FC<TFAQList> = ({ faqList }) => {
  const [activePoint, setIsActivePoint] = useState<number | null>(0);

  const onHandleAnswer = (item: number) => {
    if (activePoint === item) {
      setIsActivePoint(null);
    } else {
      setIsActivePoint(item);
    }
  };

  return (
    <div className={styles.faq__wrapper}>
      <ul className={styles.faq__list}>
        {faqList.map((item, i) => {
          return (
            <FAQItem
              onHandleAnswer={onHandleAnswer}
              key={`${item._id}__${i}`}
              item={item}
              activePoint={activePoint}
              index={i}
            />
          );
        })}
      </ul>
    </div>
  );
};

export default FAQList;
