'use client';

import React, { FC, useRef } from 'react';
import styles from './FAQItem.module.scss';
import { IFAQ } from '../../../../assets/interfaces/faqInterface/index';
import classNames from 'classnames';

type TFAQItem = {
  item: IFAQ;
  index: number;
  activePoint: number | null;
  onHandleAnswer: (item: number) => void;
};

const FAQItem: FC<TFAQItem> = ({ item, index, onHandleAnswer, activePoint }) => {
  const onShowAnswer = (index: number) => {
    onHandleAnswer(index);
  };

  const blockHeight = useRef<HTMLParagraphElement | null>(null);

  return (
    <li
      className={classNames(styles.faq__item, {
        [styles.active]: activePoint === index,
      })}
      onClick={() => {
        onShowAnswer(index);
      }}>
      <div className={styles.faq__info}>
        <div className={styles.faq__point}>{item.faqQuestion}</div>
        {item.faqAnswer && (
          <p
            ref={blockHeight}
            className={styles.answer}
            style={
              activePoint === index
                ? { height: blockHeight.current?.scrollHeight, opacity: 1 }
                : { height: '0px', opacity: 0 }
            }>
            {item.faqAnswer}
          </p>
        )}
      </div>
      <button aria-label="Expand/contract" className="button">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="32"
          height="32"
          viewBox="0 0 32 32"
          fill="none">
          <path
            d="M22.4 12.8L16 19.2L9.60002 12.8"
            stroke="#1D1D1F"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </button>
    </li>
  );
};

export default FAQItem;
