const createAnimationArr = (
  string: string,
  className: string,
  delayStart: number,
  delayGap: number
) => {
  const arr = string.split(' ').filter((item) => item !== '' && item !== '\n');

  return (
    <>
      {arr.map((item, i) => (
        <span className={className} key={`${item}-${i}`}>
          <span style={{ animationDelay: `${delayStart + i * delayGap}s` }}>{`${item}`}&nbsp;</span>
        </span>
      ))}
    </>
  );
};

export default createAnimationArr;
