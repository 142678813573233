import { IAdvInfo } from '../interfaces/advantageInterface';
import { advHomeImport, advThemeImport } from './advInfoImageImport';

const [cart, money, surfaces, chat] = advHomeImport;
const [laptop, devices, speed, update, browsers, setup] = advThemeImport;

export const advInfoHome: IAdvInfo[] = [
  {
    advName: 'Budget-Friendly Cost',
    advDescr: `Affordably priced, our themes allow your brand to stand professionally with highest functionality and modern design.`,
    advLogo: money,
    advAlt: 'Budget-Friendly Cost',
  },
  {
    advName: 'Selling Designs',
    advDescr:
      'Themes are crafted for high-quality shopping process and deliver design decisions that efficiently increase your sales.',
    advLogo: cart,
    advAlt: 'Selling Designs',
  },
  {
    advName: 'Customization Options',
    advDescr:
      'Themes come equipped with user-friendly settings that allow for customization to match both your and your customers’ needs.',
    advLogo: surfaces,
    advAlt: 'Customization Options',
  },
  {
    advName: 'Dedicated Support',
    advDescr:
      'Focus on growing your business, while we handle the technicalities, providing you with the peace of mind, as expert help is always within easy reach.',
    advLogo: chat,
    advAlt: 'Support',
  },
];

export const advInfoFull: IAdvInfo[] = [
  {
    advName: 'User-Friendly Design',
    advDescr: `Made with a user-friendly design at its core, our theme’s intuitive interface simplifies navigation and drives your sales.`,
    advLogo: laptop,
    advAlt: 'Design',
  },
  {
    advName: 'Responsive & Mobile-Optimized',
    advDescr: `Smooth shopping experience across all devices, from desktops to smartphones. We guarantee customer satisfaction and improved site's reach.`,
    advLogo: devices,
    advAlt: 'Responsive and Mobile-Optimized',
  },
  {
    advName: 'Customization Options',
    advDescr:
      'Equipped with adaptable features and customization tools, the theme can be adjusted to capture the identity of your brand.',
    advLogo: surfaces,
    advAlt: 'Customization Options',
  },
  {
    advName: 'Fast Loading Times',
    advDescr:
      'Quick loading times to keep your visitors engaged, reduce bounce rates and contribute to your website’s visibility in search engine results.',
    advLogo: speed,
    advAlt: 'Fast Loading Times',
  },
  {
    advName: 'Regular Updates',
    advDescr: `Improvements and updates delivered regularly, ensuring a smooth, secure, and efficient operation of your site.`,
    advLogo: update,
    advAlt: 'Regular Updates',
  },
  {
    advName: 'Cross-Browser Compatibility',
    advDescr:
      'Your online store will look great and operate smoothly and seamlessly across various web browsers.',
    advLogo: browsers,
    advAlt: 'Cross-Browser Compatibility',
  },
  {
    advName: 'Easy to Set Up',
    advDescr:
      'Our theme allows you to create an professional-looking online store with no coding, making setup seamlessly simple.',
    advLogo: setup,
    advAlt: 'Easy to Set Up',
  },
  {
    advName: 'Reliable Support Team',
    advDescr:
      'Exceptional assistance whenever you need it. We are here to answer any questions, resolve issues, and offer guidance.',
    advLogo: chat,
    advAlt: 'Support',
  },
];
