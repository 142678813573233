import img1 from '@/public/images/gallery/footer_cta/img1.jpg';
import img2 from '@/public/images/gallery/footer_cta/img2.jpg';
import img3 from '@/public/images/gallery/footer_cta/img3.jpg';
import img4 from '@/public/images/gallery/footer_cta/img4.jpg';
import img5 from '@/public/images/gallery/footer_cta/img5.jpg';
import img6 from '@/public/images/gallery/footer_cta/img6.jpg';
import img7 from '@/public/images/gallery/footer_cta/img7.jpg';
import img8 from '@/public/images/gallery/footer_cta/img8.jpg';
import img9 from '@/public/images/gallery/footer_cta/img9.jpg';

const gallery = [img1, img2, img3, img4, img5, img6, img7, img8, img9];

export default gallery;
