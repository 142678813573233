import React, { FC, ReactNode } from 'react';
import styles from './Label.module.scss';

type TLabel = {
  children: ReactNode;
};

const Label: FC<TLabel> = ({ children }) => {
  return <label className={styles.label}>{children}</label>;
};

export default Label;
