'use client';

import React, { FC } from 'react';
import Layout from '../../layout/Layout';
import Hero from '../../sections/hero/Hero';
import Wrapper from '../../components/wrapper/Wrapper';
import AdvantageList from '../../sections/advantageList/AdvantageList';
import { advInfoHome } from '@/src/assets/staticObjects/advInfo';
import styles from './Home.module.scss';
import HomeCTA from '../../sections/homeCTA/HomeCTA';
import LatestSection from '../../sections/latestSection/LatestSection';
import { IFAQ } from '@/src/assets/interfaces/faqInterface';
import FAQSection from '../../sections/faqSection/FAQSection';
import { ITheme } from '@/src/assets/interfaces/themeInterface';
import createAnimationArr from '@/src/assets/helpers/createAnimationArr';
import { useInView } from 'react-intersection-observer';

type THome = {
  faqData: IFAQ[];
  themesData: ITheme[];
};

const Home: FC<THome> = ({ faqData, themesData }) => {
  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0,
  });

  const heading = `How Picking Our Themes Pays Off`;

  return (
    <Layout>
      <Hero />
      <LatestSection themesData={themesData} />
      <section className={styles.advantage__section}>
        <Wrapper>
          <div ref={ref}>
            <h2 className={`heading__2 ${styles.heading} ${inView && styles.animate__heading}`}>
              {createAnimationArr(heading, styles.word__wrapper, 0, 0.01)}
            </h2>
          </div>
          <AdvantageList advList={advInfoHome} />
        </Wrapper>
      </section>
      <FAQSection faqList={faqData} />
      <HomeCTA />
    </Layout>
  );
};

export default Home;
