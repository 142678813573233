'use client';

import { useEffect, useState, useRef, useContext } from 'react';
import Button from '../../components/button/Button';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay } from 'swiper/modules';
import { HeaderContext } from '../../providers/headerContextProvider/HeaderContextProvider';
import 'swiper/css';
import 'swiper/css/autoplay';

import styles from './Hero.module.scss';
import { useMatchMedia } from '@/src/assets/hooks/useMatchMedia';
import { gallery__left } from './gallery';
import { gallery__right } from './gallery';
import NextImage from '../../components/nextImage/NextImage';
import createAnimationArr from '@/src/assets/helpers/createAnimationArr';
import { useInView } from 'react-intersection-observer';
import classNames from 'classnames';

type TDirection = 'vertical' | 'horizontal';

const Hero = () => {
  const heading = `Shopify Themes with Maximized Functionality, Minimal Cost`;
  const excerpt = `A full suite of easy-to-use functionality combined with modern, selling design, all for reasonable price.`;
  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0,
  });

  const swiperRef = useRef(null);
  const sectionRef = useRef<HTMLElement>(null);
  const [isFullyScrolled, setIsFullyScrolled] = useState(false);
  const { setIsSectionScrolled } = useContext(HeaderContext);

  const [direction, setDirection] = useState<TDirection>('vertical');
  const { isNarrowScreen } = useMatchMedia();

  useEffect(() => {
    const handleResize = () => {
      setDirection(window.innerWidth > 820 ? 'vertical' : 'horizontal');

      if (swiperRef.current) {
        //@ts-ignore
        swiperRef.current.swiper.update();
      }
    };

    handleResize();

    window.addEventListener('resize', handleResize);
    window.addEventListener('orientationchange', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
      window.removeEventListener('orientationchange', handleResize);
    };
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      const section = sectionRef.current;
      if (section) {
        const { bottom } = section.getBoundingClientRect();
        const isSectionFullyVisible = bottom >= 0;
        setIsFullyScrolled(!isSectionFullyVisible);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    isFullyScrolled ? setIsSectionScrolled(true) : setIsSectionScrolled(false);
  }, [isFullyScrolled]);

  return (
    <section className={styles.hero} ref={sectionRef}>
      <div className={styles.hero__left} ref={ref}>
        <h1 className={`heading__1 ${styles.heading} ${inView && styles.animate__heading}`}>
          {createAnimationArr(heading, styles.word__wrapper, 0, 0.01)}
        </h1>
        <p className={`excerpt ${styles.excerpt} ${inView && styles.animate__excerpt}`}>
          {createAnimationArr(excerpt, styles.word__wrapper, 0.3, 0.01)}
        </p>
        <div className={styles.buttons}>
          <Button buttonUrl="/themes" action="interactive" color="primary-light">
            Discover Themes
          </Button>
          <Button
            buttonUrl="/themes/essentials"
            action="standard"
            color="secondary-dark"
            ariaLabel="Learn More">
            Learn More
          </Button>
        </div>
      </div>

      <div className={styles.hero__right}>
        <Swiper
          ref={swiperRef}
          modules={[Autoplay]}
          direction={direction}
          className={`${styles.swiper} gallery-autoplay-swiper`}
          slidesPerView={'auto'}
          initialSlide={0}
          allowTouchMove={false}
          shortSwipes={false}
          longSwipes={false}
          loop={true}
          autoplay={{
            disableOnInteraction: false,
            pauseOnMouseEnter: false,
            delay: 0,
          }}
          spaceBetween={16}
          speed={15000}
          breakpoints={{
            1481: {
              spaceBetween: 24,
            },
          }}>
          {gallery__left &&
            [...gallery__left, ...gallery__left].map((bg, i) => {
              return (
                <SwiperSlide className={classNames(styles.background, styles.slide)} key={`background-${i}`}>
                  <NextImage
                    src={bg.src}
                    alt={`Background ${i}`}
                    fill
                    sizes="
                          (max-width: 376px) 280px,
                          (max-width: 480px) 320px, 
                          (max-width: 576px) 360px, 
                          (max-width: 768px) 400px, 
                          (max-width: 820px) 480px, 
                          (max-width: 1024px) 29.54vw, 
                          (max-width: 1920px) 33.33vw,
                                              640px"
                    loading="lazy"
                  />
                </SwiperSlide>
              );
            })}
        </Swiper>

        {!isNarrowScreen && (
          <Swiper
            modules={[Autoplay]}
            direction={direction}
            className={`${styles.swiper} gallery-autoplay-swiper ${styles.second__swiper}`}
            slidesPerView={'auto'}
            initialSlide={0}
            allowTouchMove={false}
            shortSwipes={false}
            longSwipes={false}
            loop={true}
            autoplay={{
              disableOnInteraction: false,
              pauseOnMouseEnter: false,
              delay: 0,
              reverseDirection: true,
            }}
            spaceBetween={16}
            speed={15000}
            breakpoints={{
              1481: {
                spaceBetween: 24,
              },
            }}>
            {gallery__right &&
              [...gallery__right, ...gallery__right].map((bg, i) => {
                return (
                  <SwiperSlide className={classNames(styles.background, styles.slide)} key={`background-${i}`}>
                    <NextImage
                      src={bg.src}
                      alt={`Background_${i}`}
                      fill
                      sizes="
                            (max-width: 376px) 280px,
                            (max-width: 480px) 320px, 
                            (max-width: 576px) 360px, 
                            (max-width: 768px) 400px, 
                            (max-width: 820px) 480px, 
                            (max-width: 1024px) 29.54vw, 
                            (max-width: 1920px) 33.33vw,
                            (max-width: 2559px)  640px, 800px"
                      loading="lazy"
                    />
                  </SwiperSlide>
                );
              })}
          </Swiper>
        )}
      </div>
    </section>
  );
};

export default Hero;
