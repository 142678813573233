import img1 from '@/public/images/gallery/hero/img1.jpg';
import img2 from '@/public/images/gallery/hero/img2.jpg';
import img3 from '@/public/images/gallery/hero/img3.jpg';
import img4 from '@/public/images/gallery/hero/img4.jpg';
import img5 from '@/public/images/gallery/hero/img5.jpg';
import img6 from '@/public/images/gallery/hero/img6.jpg';
import img7 from '@/public/images/gallery/hero/img7.jpg';
import img8 from '@/public/images/gallery/hero/img8.jpg';
import img9 from '@/public/images/gallery/hero/img9.jpg';
import img10 from '@/public/images/gallery/hero/img10.jpg';

export const gallery__left = [img1, img2, img3, img4, img5];
export const gallery__right = [img6, img7, img8, img9, img10];
