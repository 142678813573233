'use client';

import React, { FC, useEffect } from 'react';
import NextImage from '../../components/nextImage/NextImage';
import styles from './AdvantageList.module.scss';
import { IAdvInfo } from '../../../../assets/interfaces/advantageInterface/index';

type TAdvList = {
  advList: IAdvInfo[];
};

const AdvantageList: FC<TAdvList> = ({ advList }) => {
  return (
    <ul className={styles.adv__list}>
      {advList.map((item, i) => {
        return (
          <li className={`${styles.adv__item}`} key={`${item.advName}-${i}`}>
            <div className={styles.logo__wrapper}>
              <NextImage
                src={item.advLogo}
                alt={item.advAlt}
                fill
                sizes="(max-width: 480px) 24px, 32px"
              />
            </div>
            <p className={styles.name}>{item.advName}</p>
            <p className={`excerpt ${styles.excerpt}`}>{item.advDescr}</p>
          </li>
        );
      })}
    </ul>
  );
};

export default AdvantageList;
