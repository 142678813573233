import cart from '@/public/images/icons/cart.svg';
import money from '@/public/images/icons/money.svg';
import surfaces from '@/public/images/icons/surfaces.svg';
import chat from '@/public/images/icons/chats.svg';

import devices from '@/public/images/icons/devices.svg';
import laptop from '@/public/images/icons/laptop.svg';
import speed from '@/public/images/icons/speed.svg';
import update from '@/public/images/icons/updates.svg';
import browsers from '@/public/images/icons/browsers.svg';
import setup from '@/public/images/icons/setup.svg';

export const advHomeImport = [cart, money, surfaces, chat];

export const advThemeImport = [laptop, devices, speed, update, browsers, setup];
